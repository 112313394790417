<template>
  <div class="row">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
               :can-cancel="false"
               :is-full-page="true"></loading>
    </div>
    <div class="col-md-12 col-xs-12">
      <div id="currency-list" class="x_content">
        <!-- Cabecera -->
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <h5><b>{{results.table_name}}</b></h5>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="action-btn">
              <div class="btn-group dropdown export-btns" v-if="results.infos">
                <button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" title="Exportar resultados de búsqueda">
                  <i class="fas fa-download"></i> Exportar <span class="caret"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right" role="menu">
                  <li>
                    <a :href="results.urls[0]['export']+'?format=pdf'" target="_blank" title="Descargar archivo con formato .pdf">
                      <i class="far fa-file-pdf export-icon"></i> Exportar como PDF
                    </a>
                  </li>
                  <li>
                    <a :href="results.urls[0]['export']+'?format=xlsx'" target="_blank" title="Descargar archivo con formato .xlsx">
                      <i class="far fa-file-excel"></i> Exportar como EXCEL
                    </a>
                  </li>
                  <li>
                    <a  :href="results.urls[0]['export']+'?format=csv'" target="_blank" title="Descargar archivo con formato .csv">
                      <i class="far fa-file-text"></i> Exportar como CSV
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div>
          <form @submit.prevent>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-6">
                <div class="form-group">
                  <select v-model="results.count_by_page"  @change="search('results')" name="count_by_page" class="form-control count_by_page">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6">
                <div class="form-group search-act">
                  <input placeholder="Buscar..." v-model="results.search"  @keyup.enter="search('results')" class="form-control search-input" type="text" name="search"/>
                </div>
              </div>
            </div>
          </form>
          <!--Listado de errores-->
          <div class="errors" v-show="results.errors.length > 0">
            <ul class="bs-callout-danger">
              <button type="button" class="close" @click="results.errors = []">&times;</button>
              <li v-for="error in results.errors">{{ error }}</li>
            </ul>
          </div>
          <hr/>

          <!--Tabla de listado -->
          <div class="table-responsive">
            <table class="table table-striped table-condensed table-hover">
              <thead>
              <tr>
                <th v-for="header in results.headers"> {{header}}</th>
                <th  colspan="6" style="text-align: center;">ACCIONES</th>
<!--                <th  colspan="3" style="text-align: center;">SMS - Retener</th>-->
              </tr>
              </thead>
              <tbody>
              <tr v-if="results.infos.length == 0">
                <td :colspan="results.headers.length + 3 " >No existen elementos</td>
              </tr>
              <template v-else v-for="(obj, index) in results.infos"  >
                <tr :id="obj.id" :key="obj.id">
                  <td v-for="(row,index) in results.rows" v-bind:class="{ tbdanger: obj.danger }" >
                    <span v-if="row !='ra'"  v-html="cleanMessage(obj[row])" ></span>
                    <span v-else  v-html="obj[row]"  ></span>
                  </td>
                  <td class="action-btns">
                    <a :href="obj.view" class="btn btn-default btn-xs" title="Ver" v-show="obj.view" v-bind:class="{ tbdanger: obj.danger }"><i class="far fa-file-alt"></i></a>
                  </td>
                  <td class="action-btns">
                    <a :href="obj.edit" class="btn btn-warning btn-xs" title="Editar" v-show="obj.edit"><i class="fas fa-edit"></i></a>
                  </td>
                  <td class="action-btns" v-for="(accion, index) in obj.actions" >
                    <a  v-if="accion[7] == 'get'" :href="accion[1]" class="btn btn-default btn-xs" :title="accion[3]" v-show="accion[0]" v-bind:class="{ tbdanger: obj.danger }" ><i :class="accion[2]"></i> </a>
                    <span  v-if="accion[7] == 'post' && 'unblock'=== accion[4]" class="btn btn-default btn-xs" @click="makeRequest(obj, index, accion[4], $event)" :title="accion[3]" v-show="accion[0]" v-bind:class="{ tbdanger: obj.danger }" ><i :class="accion[2]"></i> </span>
                    <input type="checkbox" :id="'s'+obj.id" v-if="accion[7] === 'post' && 'send_sms'=== accion[4]" class="btn btn-default btn-xs" @click.self="makeRequest(obj, index, accion[4], $event)" v-model="obj.send_sms" >
                    <label for="checkbox" v-if="accion[7] === 'post' && 'send_sms'=== accion[4] && obj.send_sms === true" >SMS</label><label for="checkbox" v-else-if="accion[7] === 'post' && 'send_sms'=== accion[4] && obj.send_sms === false">No SMS</label>
                    <input type="checkbox" :id="'r'+obj.id" v-if="accion[7] === 'post' && 'retained'=== accion[4]" class="btn btn-default btn-xs" @click="makeRequest(obj, index, accion[4], $event)" v-model="obj.retained" >
                    <label for="checkbox"  v-if="accion[7] === 'post' && 'retained'=== accion[4] && obj.retained === true">Retener</label><label for="checkbox"  v-if="accion[7] === 'post' && 'retained'=== accion[4] && obj.retained === false">No Retener</label>
                  </td>
                  <td class="action-btns">
                    <span class="btn btn-danger btn-xs" @click="removeObj(obj, index, results.total_count)"  title="Eliminar" v-show="obj.delete"><i class="fas fa-trash-alt"></i></span>
                  </td>
                </tr>

              </template>
              </tbody>
            </table>
          </div>
          <form @submit.prevent class="tablepager" v-if="showTablePager">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                Página {{ results['page'] }} de {{ total_pages() }} (Total de elementos: {{ results.total_count }})
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="input-group">
                  <div class="input-group-btn">
                    <span class="btn btn-default" title="Primera página" :disabled="results['page'] <= 1" @click="pagerArrows('first', results)"><i class="fas fa-step-backward"></i></span>
                    <span class="btn btn-default" title="Página anterior" :disabled="results['page'] <= 1" @click="pagerArrows('prev', results)"><i class="fas fa-chevron-left"></i></span>
                  </div>
                  <input type="text" class="form-control" v-model="results.page" title="Página actual"  @keyup.enter="search" @input="resultsPage">
                  <div class="input-group-btn">
                    <span class="btn btn-default" title="Página siguiente" :disabled=" results['page'] >= total_pages()" @click="pagerArrows('next', results)"><i class="fas fa-chevron-right"></i></span>
                    <span class="btn btn-default" title="Última página" :disabled="results['page'] >= total_pages()" @click="pagerArrows('last', results)"><i class="fas fa-step-forward"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["results"],
    methods: {
      cleanMessage(message) {
        return this.$sanitize(message);
      },
      errorMsm(response){
        let message = "";
        if (response.status === 0) {
          message = 'SIN CONEXIÓN: Verifique su conexión de red.';
        } else if(response.status == 400){
          message = 'EXISTEN PROBLEMAS EN EL REQUEST: Existen problemas con los datos enviados, intente nuevamente';
        } else if(response.status == 401){
          message = 'AUNTENTICACIÓN FALLIDA: Autentiquece nuevamente.';
        }else if (response.status == 403) {
          message == "AUNTENTICACIÓN FALLIDA: Autentiquece nuevamente.";
        } else if (response.status == 404) {
          message = 'PÁGINA NO ENCONTRADA: La página a la que está tratando de acceder nos se encontró, intente nuevamente';
        } else if (response.status == 500) {
          message = 'ERROR: Ha ocurrido un error que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
        } else if (response.status == 503){
          message = "Service unavailable.";
        } else if (response.statusText === 'parsererror') {
          message = 'ERROR: Ha ocurrido un error que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
        } else if (response.statusText === 'timeout') {
          message = 'TIME  OUT: Tiempo de espera superado, verifique su conexión de red.';
        } else if (response.statusText === 'abort') {
          message = 'REQUEST ABORTADO: La operación ha sido abortada';
        } else {
          message = 'ERROR: Ha ocurrido un error que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
        }
        return message;
      },
      removeObj(obj, index, total) {
        const login_url = this.$props.results.urls[0]['login'];
        const url = obj.delete;
        this.$dialog.confirm(obj.confirmDialog, {
          okText:'Aceptar',
          cancelText:'Cerrar',
          loader: true,
          // And a dialog object will be passed to the then() callback
        })
                .then((dialog) => {
                  // Triggered when proceed button is clicked
                  this.$http.delete( url, {params: {id: obj.id, total: total }}).then(response => {
                            if(response.body.results != null && response.body.results.errors != null){
                              this.$props.results.errors = [response.body.results.errors];
                              return
                            }
                            if (response.body.results == null && response.body.substring(0, 10) == 'Turbolinks'){
                              Turbolinks.clearCache();
                              Turbolinks.visit(login_url, {"action":"replace"});
                            }
                            obj._destroy = true;
                            this.results.infos.splice(index, 1);
                            this.$delete;
                            this.$props.results.total_count = this.$props.results.total_count - 1 ;
                          }, response=> {
                            let message = this.errorMsm(response);
                            this.$props.results.errors = [message];
                          }
                  );
                  // dialog.loading(false) // stops the proceed button's loader
                  // dialog.loading(true) // starts the proceed button's loader again
                  dialog.close(); // stops the loader and close the dialog
                })
                .catch(() => {
                  // Triggered when cancel button is clicked
                });
      },
      makeRequest(obj, index, type, e, event){
        let vm = this;
        const login_url = this.$props.results.urls[0]['login'];
        let url = null;
        let confirmation = null;
        let confirm_dialog = null;
        let request_type = null;
        let data = {id : obj.id };
        $.each( obj.actions, function( i, value ){
          if(type === value[4]){
            url = value[1];
            confirmation = value[5];
            confirm_dialog = value[6];
            request_type = value[7];
          }
         });
        if (confirmation === true ){
          this.$dialog.confirm(confirm_dialog, {
            okText:'Aceptar',
            cancelText:'Cerrar',
            loader: false,
            // And a dialog object will be passed to the then() callback
          })
            .then((dialog) => {
              this.isLoading = true;
              this.$http.post(url, {data: data}).then(response => {
                if(vm.isBlank(response)) {
                  this.$props.results.errors = ['No fue posible recuperar información desde el servidor'];
                  return;
                }
                if (response.body.results == null && response.body.substring(0, 10) == 'Turbolinks'){
                  Turbolinks.clearCache();
                  Turbolinks.visit(login_url, {"action":"replace"});
                }
                if(e.target.tagName === 'I'){
                  e.target.classList.toggle('fa-lock-open');
                  e.target.classList.remove('fa-lock');
                  e.target.parentElement.classList.toggle('disabled');
                }else
                  if (e.target.tagName === 'SPAN'){
                    e.target.classList.toggle('disabled');
                    e.target.querySelector('i').classList.toggle('fa-lock-open');
                    e.target.querySelector('i').classList.remove('fa-lock');
                }
                this.isLoading = false;
              }, response=> {
                let message = this.errorMsm(response);
                this.$props.results.errors = [message];
                this.isLoading = false;
              });
            })
            .catch(() => {
              // Triggered when cancel button is clicked
            });

        }else{
          this.isLoading = true;
          this.$http.post(url, {data: data}).then(response => {
            if(vm.isBlank(response)) {
              this.$props.results.errors = ['No fue posible recuperar información desde el servidor'];
              return;
            }
            if (response.body.results == null && response.body.substring(0, 10) == 'Turbolinks'){
              Turbolinks.clearCache();
              Turbolinks.visit(login_url, {"action":"replace"});
            }

            this.$set(this.$props['results'], 'title', '123th avenue.');
            //console.log(this.$props['results'])
            //this.$props['results'].page = response.body.results.page;
            //this.$props['results'].count_by_page = response.body.results.count_by_page;
            //this.$props['results'].total_count =  response.body.results.total_count;
            //this.$emit("update-lists", response.body.results);
            this.isLoading = false;
          }, response=> {
            let message = this.errorMsm(response);
            this.$props.results.errors = [message];
            this.isLoading = false;
          });
        }
      },
      search(){
        let vm = this;
        let force = force || false;
        if (vm.locked && !force) {
          return;
        }
        const login_url = this.$props.results.urls[0]['login'];
        const search_url = this.$props.results.urls[0]['search'];
        let data = $.extend(true, {}, vm.results);
        //EN GET los parámetros se pasan {params: {data: data}} en post {data: data}
        this.isLoading = true;
        this.$http.post(search_url, {data: data}).then(response => {
          if(vm.isBlank(response)) {
            this.$props.results.errors = ['No fue posible recuperar información desde el servidor'];
            return;
          }
          if (response.body.results == null && response.body.substring(0, 10) == 'Turbolinks'){
            Turbolinks.clearCache();
            Turbolinks.visit(login_url, {"action":"replace"});
          }
          this.$props['results'].page = response.body.results.page;
          this.$props['results'].count_by_page = response.body.results.count_by_page;
          this.$props['results'].total_count =  response.body.results.total_count;
          this.$emit("update-lists", response.body.results);
          this.isLoading = false;
        }, response=> {
          let message = this.errorMsm(response);
          this.$props.results.errors = [message];
          this.isLoading = false;
        })
      },
      showTablePager() {
        let vm = this;
        return vm.isPresent(vm.results) &&
                vm.isPresent(vm.total_pages) &&
                vm.total_pages > 1
                ;
      },
      pagerArrows(action, results) {
        let vm = this;
        switch(action) {
          case 'first':
            results.page = 1;
            break;
          case 'prev':
            results.page--;
            break;
          case 'next':
            results.page++;
            break;
          case 'last':
            results.page = vm.total_pages();
            break;
        }
        this.search();
      },
      resultsPage(e){
        let vm = this;
        let results = this.$props['results'];
        let value = e.target.value.replace(/[^0-9]*/g, '');
        if (!(value > 0)){
          results.page = '1';
          this.$nextTick(function () {
            $('.tablepager input').select();
          })
        } else if (vm.isPresent(vm.results) && vm.isPresent(vm.total_pages()) && value > parseInt(vm.total_pages())) {
          vm.results.page = vm.total_pages();
        } else if(value !=results['page']) {
          results.page = value;
        }
      },
      total_pages() {
        let vm = this;
        let results = this.$props['results'];
        let result = 1;
        if (vm.isPresent(results) && vm.isPresent(results.count_by_page) &&
                results.total_count > 0 && results.count_by_page > 0) {
          result = parseInt(results.total_count/results.count_by_page);

          if (results.total_count % results.count_by_page > 0) {
            result += 1;
          }
        }
        return result;
      },
      showDetails(obj) {
        obj.show_details = !obj.show_details;
      }
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
        componentKey: 0,
      }
    }
  }
</script>






<style scoped>

  p {
    font-size: 2em;
    text-align: center;
  }

  [v-cloak] { display: none; }

  #currency-list .action-btn { text-align: right; }

  #currency-list { margin-top: 20px; }

  #currency-list hr { margin-top: 0px; }

  #currency-list .count_by_page { max-width: 80px; }

  #currency-list .search-act { text-align: right; }

  #currency-list .search-act input {
    max-width: 180px;
    display: inline-block;
  }

  #currency-list table tbody td {
    vertical-align: middle;
  }

  #currency-list .tablepager .input-group .input-group-btn .btn {
    padding: 4px 12px;
    font-size: 10px;
    cursor: pointer;
    margin-right: 0px;
  }

  #currency-list .tablepager .input-group-btn:first-child>.btn,  #currency-list .tablepager .input-group-btn:first-child>.btn-group {
    margin-right: -1px;
  }

  #currency-list .tablepager .input-group-addon:hover { background-color: #ddd; }
  #currency-list .tablepager .input-group-addon:active { background-color: #ccc; }

  #currency-list .tablepager {
    line-height: 24px;
  }

  #currency-list .tablepager .input-group {
    width: 200px;
    float: right;
  }

  #currency-list .tablepager input {
    text-align: center;
    height: 24px;
  }

  #currency-list .tbdanger{
    color: #721c24;
  }
  #currency-list .errors .bs-callout-danger {
    margin: 0;
    padding: 15px 30px 15px 15px;
    border-left: 5px solid #dFb5b4;
    background-color: #fcf2f2;
    list-style: none;
  }

  #currency-list .voucher-content {
    padding: 15px;
    background-color: white !important;
  }

  #currency-list .show-details {
    cursor: pointer;
  }
  #currency-list .voucher-content b {
    width: 150px;
    display: inline-block;
    text-align: right;
    margin-right: 15px;
  }

  #currency-list .dropdown-menu {
    min-width: 100px;
  }

  #currency-list .dropdown-menu-right.dropdown-menu:after {
    right: 5%;
  }

  #currency-list .recent-work {
    background-color: #ededed;
    font-weight: bold;
  }

  #currency-list td:first-child, #currency-list th:first-child  {
    padding-left: 15px;
  }

  #currency-list .recent-work td:first-child::before {
    content: '\f0da';
    font: normal normal normal 14px/1 FontAwesome;
    margin-left: -10px;
    font-size: 14px;
    padding-right: 5px;
  }

  #currency-list .results .daterange-dot:before {
    content: ':';
    float: left;
    margin-left: -13px;
    font-size: 20px;
    font-weight: bold;
  }

  @media (min-width: 768px) {
    #currency-list .results .daterange-dot {
      margin-top: 22px;
    }
  }


  #currency-list .details .details-data th {
    text-align: right;
    width: 122px;
    vertical-align: top;
    padding: 0 0 10px 0;
    margin: 0 0 10px;
  }

  #currency-list .details .details-data td {
    padding-left: 15px;
    padding-bottom: 10px;
  }

  #currency-list .export-btns {
    margin-bottom: 5px;
    margin-right: 5px;
  }
  #currency-list .export-btns .dropdown-menu { margin-top: 6px; }
  #currency-list .export-btns .dropdown-menu li a {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
  }
  #currency-list .export-btns .dropdown-menu li a i { margin-right: 9px; font-size: 18px; }
  #currency-list .export-btns .btn .caret { margin-left: 0; }

  #currency-list .export-btns .dropdown-menu {
    left: auto;
  }

  #currency-list .action-btn .btn:last-child {
    margin-right: 0px;
  }

  #currency-list .action-btns .btn[disabled] {
    opacity: .3;
  }

  #currency-list .action-btns .btn {
    min-width: 25px;
  }

  #currency-list .form-group.action-btn {
    margin-top: 5px;
  }

  #currency-list .diff {
    text-decoration: line-through;
    color: #c70101;
  }

  #currency-list .details td span {
    line-height: inherit;
  }

  #currency-list .details-divider { margin-top: 20px; }

  #currency-list .details-divider .badge {
    float: left;
    position: relative;
    display: inline-block;
    top: -11px;
    min-width: 23px;
    border-radius: 15px;
  }

  .centered-modal.in {
    display: flex !important;
  }
  .centered-modal .modal-dialog {
    margin: auto;
  }
  .btn_agency{
    color: #c70101;
  }
  .dropdown-menu>li>span {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    cursor:pointer;
  }
</style>
