<template>
    <div class="filter">
        <div class="card-title">
            <h3>Datos del FAQ <small>{{r.code}}</small></h3>
        </div>
        <div class="hr-text hr-text-left text-indigo">
          <span class="text-indigo">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-md" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"></path><circle cx="12" cy="12" r="9"></circle><line x1="8" y1="12" x2="12" y2="16"></line><line x1="12" y1="8" x2="12" y2="16"></line><line x1="16" y1="12" x2="12" y2="16"></line></svg>
          </span>
        </div>
        <div class="card-title text-indigo"><u><span v-html="cleanMessage(r.faq_question)"></span></u></div>
        <p><span v-html="cleanMessage(r.faq_answer)"></span></p>
    </div>
</template>
<script>
export default {
    props: ['r', 'history'],
    methods: {
        cleanMessage(message){
            return this.$sanitize(message);
        }
    }
}
</script>