<template>
    <div class="col-md-12 col-sm-12 col-xs-12 ">

        <div   v-bind:class="{ 'alert alert-info alert-dismissable': r.danger == 'isActive', 'alert alert-danger alert-dismissable': r.danger == 'hasError' }">
            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
            <strong class="fa fa-exclamation-triangle fa-lg"></strong> {{r.reklamationsgrund}}
        </div>
        <!-- Modal -->
<!--        <div class="modal fade show-news-modal" :id="'show-news-modal-'+ r.id " tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">-->
<!--            <div class="modal-dialog modal-lg" role="document">-->
<!--                <div class="modal-content">-->
<!--                    <div class="modal-header" :style="{backgroundColor:r.backgrouond_color, borderColor:r.border_color, color:r.font_color}">-->
<!--                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
<!--                        <h4 class="modal-title">-->
<!--                            <div><b> Notificaciones </b></div>-->
<!--                        </h4>-->
<!--                    </div>-->
<!--                    <div class="modal-body">-->
<!--                        <span v-html="cleanMessage(r.description)"></span>-->
<!--                    </div>-->
<!--                    <div class="modal-footer">-->
<!--                        <button type="button" class="btn btn-default" data-dismiss="modal">Cerrar</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <!-- FIN DEL Modal -->
    </div>
</template>

<script>
    export default {
        props: ['r', 'history'],
        methods: {
            cleanMessage(message) {
                return this.$sanitize(message);
            },
        },
        data: function () {
            return {notice_details: 'r' }
        }
    }
</script>