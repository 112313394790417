<template>
    <div class="filter">
    <div class="row">
        <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="form-group mb-3">
                <label class="form-label"><abbr title="Obligatorio">*</abbr>País: </label>
                <input type="text" v-model="r.name"  class="form-control required">
            </div>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="form-group mb-3">
                <label class="form-label"><abbr title="Obligatorio">*</abbr>Código:</label>
                <input type="text" v-model="r.code" class="form-control required">
            </div>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="form-group mb-3">
                <label class="form-label"><abbr title="Obligatorio">*</abbr>Orden: </label>
                <input type="number" v-model="r.sequence"  class="form-control required">
            </div>
        </div>
    </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                    <label class="form-check">
                        <input type="checkbox" class="form-check-input" v-model="r.active" :value="r.id">
                        <span class="form-check-label" v-if="r.active">Activo</span> <span class="form-check-label" v-else >Inactivo</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="form-footer">
            <button class="btn btn-primary" v-on:click="modifyCurrency()">Aceptar</button>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['r', 'history'],
        methods: {
            modifyCurrency() {
                if (this.r.id != null){
                    this.$http.put('/admin/currencies/'+this.r.id, {currency: this.r}).then(response => {
                        Turbolinks.visit('/admin/currencies/' + response.body.id)
                    }, response=> {
                        console.log(response);
                        }
                    )
                }

            }
        },
        data: function () {
            return {currency: 'r' }
        }
    }
</script>