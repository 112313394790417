<template>
    <div class="filter">
        <div class="row">
            <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="form-group">
                    <label class="control-label text required" ><abbr title="Obligatorio">*</abbr>Abbreviation: </label>
                    <input type="text" v-model="r.abbreviation"  class="form-control">
                </div>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="form-group">
                    <label><abbr title="Obligatorio">*</abbr>Details: </label>
                    <input type="text" v-model="r.details"  class="form-control required">
                </div>
            </div>
        </div>
        <div class="input-group-btn">
            <button class="btn btn-success" v-on:click="modifyCurrency()">Aceptar</button>
        </div>
    </div>
</template>



<script>
    export default {
        props: ['r', 'history'],
        methods: {
            modifyCurrency() {
                if (this.r.id != null){
                    this.$http.put('/admin/merchant_translates/'+this.r.id, {merchant_translate: this.r}).then(response => {
                        Turbolinks.visit('/admin/merchant_translates/')
                    }, response=> {
                        console.log(response);
                        }
                    )
                }

            }
        },
        data: function () {
            return {merchant_translate: 'r' }
        }
    }
</script>