<template>
    <div class="filter">
        <div class="row">
            <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="form-group">
                    <label><abbr title="Obligatorio">*</abbr>Nombre: </label>
                    <input type="text" v-model="r.name"  class="form-control required">
                </div>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="form-group">
                    <label><abbr title="Obligatorio">*</abbr>Descripción: </label>
                    <input type="text" v-model="r.description" class="form-control required">
                </div>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="form-group">
                    <label><abbr title="Obligatorio">*</abbr>Usuario: </label>
                    <input v-model="r.user"  class="form-control required">
                </div>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="form-group">
                    <label><abbr title="Obligatorio">*</abbr>PASSWORD: </label>
                    <input type="password" v-model="r.password"  class="form-control required">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2 col-sm-12 col-xs-12">
                <div class="form-group">
                    <input type="checkbox" class="custom-control-input" v-model="r.active" :value="r.id"> <label v-if="r.active">Activo</label> <label v-else >Inactivo</label>
                </div>
            </div>
        </div>
        <div class="input-group-btn">
            <button class="btn btn-success" v-on:click="modifyCurrency()">Aceptar</button>
<!--            <button class="btn btn-success" v-on:click="testConnections()"><i class="fas fa-cloud-upload-alt" title="Probar conexión"></i></button>-->
        </div>
    </div>
</template>



<script>
    export default {
        props: ['r', 'history'],
        methods: {
            modifyCurrency() {
                if (this.r.id != null){
                    this.$http.put('/admin/admin_acquirers/'+this.r.id, {acquirer: this.r}).then(response => {
                        Turbolinks.visit('/admin/admin_acquirers/' + response.body.id)
                    }, response=> {
                        console.log(response);
                        }
                    )
                }

            },
            // testConnections() {
            //     if (this.r.id != null){
            //
            //         this.$http.post('/admin/admin_acquirers/'+this.r.id+'/test_connexion', {id: this.r}).then(response => {
            //              console.log(response);
            //                 Turbolinks.visit('/admin/admin_acquirers/')
            //             }, response=> {
            //                 console.log(response);
            //             }
            //         )
            //     }
            //
            // }
        },
        data: function () {
            return {currency: 'r' }
        }
    }
</script>